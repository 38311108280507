import { ReactNode, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import env from "../config/env";

const reCaptchaKey = env<string>(`GOOGLE_RECAPTCHA_SITE_KEY`) as string;

export type Props = {
  children: ReactNode;
};

function RecaptchaProvider(props: Props) {
  const { children } = props;

  useEffect(() => {
    return () => {
      const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' + reCaptchaKey + '\']';

      const script = document.querySelector(scriptSelector);
        
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        // language="[optional_language]"
        useRecaptchaNet={false}
        useEnterprise={false}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
          id: "test",
        }}
        // container={{ // optional to render inside custom element
        //   element: "[required_id_or_htmlelement]",
        //   parameters: {
        //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
        //     theme: 'dark', // optional, default undefined
        //   }
        // }}
      >
        {children}
    </GoogleReCaptchaProvider>
  );
} 

export { RecaptchaProvider };